.px-\[6rem\] { padding: 8rem 2rem 4rem 2rem !important; text-align: center;}
.text-align { text-align: center;}
.align-items { align-items: justify; text-align: justify;}


.footer-margin {
    margin-top: 200px;
}


@media (min-width: 768px) {
    .px-\[6rem\] { padding: 8rem 5rem 6rem 5rem !important; }
    .align-items { align-items: flex-start;}
}
@media (min-width: 1024px) {
    .px-\[6rem\] { padding: 8rem 6rem 6rem 6rem !important; }
    .text-align { text-align: left;}
    .align-items { align-items: flex-start;}
    .footer-margin {
        margin-top: 320px;
    }
}
