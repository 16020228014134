/* .App {
  text-align: center;
} */
.bg-white-logo { position: relative; background-color: #ffffff; height: 100vh; width: 100%;}
.loader-logo { background-image: url('./assets/logo.png'); height: 300px; width: 300px; background-position: center; background-repeat: no-repeat; background-size: cover;}
nav { transition:  all 0.5s;}
*{
  --primary: #8A2320;
}
/* .logo { height: 65px !important;}
@media(min-width: 1024px) {
  .logo { height: 148px !important;}
} */
.our-product-section { color: #8a2320;}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */
/* 
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.align-center-companyBrief {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}
/* 
.classrelative {
  position: absolute;
}

@media(min-width: 1024px) {
  .classrelative {
    position: relative;
  }
} */

@media(min-width: 1430px){
  .align-center-companyBrief {
    margin-left: auto;
    margin-right: auto;
    max-width: 950px;
  }
}


