.the-company { margin: 30px;}

.company-title { position: unset; padding-bottom: 0; margin-top: 50px; display: flex; justify-content: center; flex-direction: column; align-items: center;}
.company-title .company-text {  position: unset; top: 90%; text-align: center; margin-top: 30px;}
.the-company-content { position: unset; height: auto; margin-bottom: 30px; padding: 30px; display: flex; align-items: center;}
.the-company-content .company-img { position: unset; top: 0%; left: 0%;} 

@media(min-width: 1024px) {
    .the-company { margin: 0 0 0 100px;}
    .company-title { position: relative; padding-bottom: 400px; display: block; margin-top: 109px; }
    .company-title .company-text {position: absolute; top: 80%; margin-top: 0px; text-align: unset;}
    .the-company-content { position: relative; height: 522px; margin-bottom: 350px; padding-top: 137px;}
    .the-company-content .company-img { position: absolute; top: 70%; left: -10%;} 

}