.input-group {
    position: relative;
   }
   
   .input {
    border: solid 1.5px #9e9e9e;
    background: none;
    font-size: 1rem;
    transition: border 150ms cubic-bezier(0.4,0,0.2,1);
   }
   
   .user-label {
    position: absolute;
    left: 15px;
    color: #838383;
    pointer-events: none;
    transform: translateY(0.5rem);
    transition: 150ms cubic-bezier(0.4,0,0.2,1);
   }
   
   .input:focus {
    outline: none;
   }
   
   .input:focus ~ label, .active-input ~ label{
    transform: translateY(-50%) scale(0.8) translateX(-15px);
    background-color: white;
    padding: 0 .2em;
   }