/* form { margin-top: 30px; max-width: auto; margin-left: 10px !important; margin-right: 10px !important; background-color: #FFFFFF; box-shadow: 2px 10px 28px rgba(138, 35, 32, 0.1); border-radius: 10px;} */
.send-btn { font-size: 13px; font-weight: 600; line-height: 20px; padding: 7px 20px;}
.enquiry-text { color: #8A2320; font-weight: 600; font-size: 24px; margin-bottom: 34px; padding-top: 30px;}
.enquiry-form {max-width: auto; margin: 0 20px;}
.select-product { margin-bottom: 35px;}


@media(min-width: 1024px) {
    /* form { margin-top: 107px; max-width: 950px; margin-left: auto !important; margin-right: auto !important;  box-shadow: 2px 10px 28px rgba(138, 35, 32, 0.1); border-radius: 10px;} */
    .enquiry-text { color: #8A2320; font-weight: 600; font-size: 24px; margin-bottom: 34px; padding-top: 134px;}
    .enquiry-form {max-width: 450px; margin: auto;}
}