.productCardlist ul li{
    list-style-type:  disc !important;
    /* padding: 0 1rem; */
}

.productCardlist ul{
    padding: 1rem;
}

.productCardImg{
    transition: all 0.3s ease;
}

.productCardImg:hover{
    transform: scale(1.1);
}
.our-product { background-color: #7f251d; color: #fff; font-size: 24px; border-radius: 10px;}
.leftArrow { position: absolute; top: 50%; left: 0%; z-index: 100;}
.rightArrow { position: absolute; top: 50%; right: 0%;}

@media(min-width: 1024px) {
    .leftArrow { position: absolute; top: 50%; left: 0%;}
    .rightArrow { position: absolute; top: 50%; right: 0%;}
}
.product-image img { transition: all 0.5s;}
.product-image img:hover { transform: scale(1.2);}