.set-box { margin-bottom: 50px;}
.section-set { position: unset;}

.section-set .image-set { position: unset; margin-top: 30px;}


@media(min-width: 768px) {
    .set-box { margin-bottom: 300px;}
    .section-set { position: relative;}
    .section-set .image-set { position: absolute; top: 93%; left: 70%;}
}
@media(min-width: 1024px) {
    .set-box { margin-bottom: 350px;}
    .section-set { position: relative;}
    .section-set .image-set { position: absolute; top: 93%; left: 76%;}
}
@media(min-width: 1440px) {
    .set-box { margin-bottom: 280px;}
    .section-set { position: relative;}
    .section-set .image-set { position: absolute; top: 93%; left: 76%;}
}

.director {background-color: #8A2320; color: #ffffff; position: unset; left: 30%; right: 0; padding: 30px; margin-bottom: 50px;}
.director-text { width: 100%; position: unset;}
.director-text .director-img { position: unset; top: 80; left: -56%; margin-top: 30px;}


@media(min-width: 768px) {
    .director {position: relative; left: 30%; right: 0; padding: 40px 120px; margin-bottom: 200px; max-width: 70%;}
    .director-text { position: relative;}
    .director-text .director-img { position: absolute; top: 80%; left: -104%;}
}
@media(min-width: 1024px) {
    .director {position: relative; left: 30%; right: 0; padding: 48px 110px; margin-bottom: 400px; max-width: 70%;}
    .director-text { position: relative;}
    .director-text .director-img { position: absolute; top: 94%; left: -94%;}
}
@media(min-width: 1440px) {
    .director {position: relative; left: 30%; right: 0; padding: 48px 129px; margin-bottom: 400px; max-width: 70%;}
    .director-text { position: relative;}
    .director-text .director-img { position: absolute; top: 80%; left: -50%;}
}

.img-flex-col { display: flex !important; flex-direction: unset !important; margin-bottom: 80px;}


@media(max-width: 768px) {
    .img-flex-col { display: flex !important; flex-direction: column-reverse !important; margin-bottom: 20px;}
}